// variables
$primary-color: #feecb7;
$secondary-color: #a22301;
$ternary-color: #ff9579;
$white-color: #ffffff;
$black-color: 000000;

.text-balance {
  text-wrap: balance !important;
}

// home banner start
.home_banner {
  height: 100vh !important;
}

//home vide section start
.home_video_btn {
  height: 100px !important;
  width: 100px !important;
}

.video_card {
  padding: 17px;
  color: #000;

  & .video_card_title {
    font-weight: 300;
    font-size: 16px;
  }

  & .video_card_description {
    line-height: 1.5;
    font-size: 18px;
    font-style: italic;
  }

  & .vide_card_arrow {
    position: absolute;
    bottom: -250px;
    left: -60px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: -9px;
    z-index: -2;
    height: 20px;
    width: 20px;
    background-color: #d79e00; //#ef9d87;
    transform: rotate(40deg);
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 45%;
    height: 15px;
    width: 15px;
    background-color: #fdba01;
    border-radius: 50%;
    border: 5px solid #00000066;
  }
}

// media query start
@media only screen and (max-width: 1400px) {
  .home_banner {
    height: 100vh !important;
  }
}
@media only screen and (max-width: 1300px) {
  .home_banner {
    height: 100vh !important;
  }
}
@media only screen and (max-width: 1280px) {
  .home_banner {
    height: 100vh !important;
  }
}
@media only screen and (max-width: 1279px) {
  .home_banner {
    height: 83vh !important;
  }
}
@media only screen and (max-width: 1024px) {
  .home_banner {
    height: 90vh !important;
  }
  .video_card {
    & .vide_card_arrow {
      bottom: -200px;
      left: -25px;
      & img {
        width: 130px;
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .home_banner {
    height: 42vh !important;
  }
  .video_card {
    & .vide_card_arrow {
      bottom: -145px;
      left: 5px;
      & img {
        width: 100px;
      }
    }
  }
}
@media only screen and (max-width: 880px) {
  .home_banner {
    height: 44vh !important;
  }
}
@media only screen and (max-width: 768px) {
  .home_banner {
    height: 48vh !important;
  }
  .video_card {
    height: 100px !important;
    width: 130px !important;
    right: 15px !important;
    padding: 7px !important;
    & .video_card_title {
      font-size: 10px;
    }

    & .video_card_description {
      font-size: 12px;
    }
    & .vide_card_arrow {
      bottom: -75px;
      left: 20px;
      & img {
        width: 50px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .home_banner {
    height: 55vh !important;
  }
}
@media only screen and (max-width: 576px) {
  .home_banner {
    height: 56vh !important;
  }
}
@media only screen and (max-width: 500px) {
  .home_banner {
    height: 37vh !important;
  }
}
@media only screen and (max-width: 460px) {
  .home_banner {
    height: 35vh !important;
  }
}

// gradient bg
.gradient_bg {
  background: linear-gradient(
    25deg,
    rgba(254, 236, 183, 1) 0%,
    rgba(255, 149, 121, 1) 90%
  ) !important;
}

// accordian button
.accordion-button {
  background-color: #feecb7 !important;
}
.accordion-button.collapsed {
  background-color: #fff !important;
}

.text_balance {
  text-wrap: balance;
}

// arrow to next indicator

.arrow {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -65%);
  transform: rotate(270deg);
  // cursor: pointer;
}

.arrow span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

// about lower bg
@media only screen and (max-width: 767px) {
  .about_lower_bg {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  }
}
