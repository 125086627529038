@tailwind components;
@tailwind utilities;
@import "react-toastify/dist/ReactToastify.css";
input,
textarea {
  box-shadow: none !important;
}
.error_message {
  font-size: 12px;
  position: absolute;
  line-height: 1.7;
  color: tomato;
}
